<template>
  <div :class="containerClass">
    <div :class="contentClass">
      <div :class="contentColumnClass">
        <h1 :class="titleClass">
          <span class="tw-whitespace-nowrap">Weather Forecasts</span>
          <span class="tw-whitespace-nowrap">& Snow Reports</span>
        </h1>
        <p :class="copyClass">
          OpenSnow is your trusted source for the most accurate weather forecast, snow report,
          high-resolution weather maps, and ski conditions information.
        </p>
        <div
          v-if="showActionButtons"
          class="tw-flex"
        >
          <Button v-bind="startFreeTrialButtonProps">
            <span class="tw-block tw-text-lg tw-font-normal">
              Start Free Trial
            </span>
            <small class="tw-block tw--mt-1 tw-font-normal tw-text-xs">
              No credit card required
            </small>
          </Button>
          <Button v-bind="buyNowButtonProps">
            <span class="tw-block tw-text-lg tw-font-normal tw-leading-loose">
              Buy Now
            </span>
          </Button>
        </div>
      </div>
      <div :class="imageColumnClass">
        <img
          class="tw-h-full"
          src="https://blizzard.opensnow.com/app-images/500x1004/favorite-locations-alta-highlands-taos-16.png"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'OpenSnowHeroBlock',

  props: {
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    buyNowButtonProps() {
      return {
        additionalClassNames: 'tw-flex-grow tw-ml-1',
        displayBlock: true,
        extraLarge: true,
        to: '/buy?source=homepage',
        type: ButtonTypes.homePageBuyNow,
      };
    },

    containerClass() {
      return [
        'tw-pt-10 md:tw-pt-20 tw-pb-12',
        'tw-bg-cover',
        this.$style.container,
      ];
    },

    contentClass() {
      return 'md:tw-flex container';
    },

    contentColumnClass() {
      return [
        'md:tw-w-3/5 lg:tw-w-1/2',
        'tw-mb-3 lg:tw-ml-24 lg:tw-px-4',
      ];
    },

    copyClass() {
      return [
        'tw-mb-5',
        'tw-text-center md:tw-text-left',
        'tw-text-base tw-leading-tight md:tw-text-2xl md:tw-leading-8',
        'tw-font-light tw-text-white',
      ];
    },

    imageColumnClass() {
      return [
        'tw-flex lg:tw-block',
        'md:tw-w-2/5 lg:tw-w-1/3 lg:tw-px-4',
        'tw-justify-center md:tw-justify-end',
        this.$style.imageColumn,
      ];
    },

    startFreeTrialButtonProps() {
      return {
        additionalClassNames: 'tw-flex-grow tw-mr-1',
        displayBlock: true,
        extraLarge: true,
        to: 'user/register',
        type: ButtonTypes.allAccess,
      };
    },

    titleClass() {
      return [
        'tw-mb-2.5 md:tw-mb-8',
        'tw-text-center md:tw-text-left',
        'tw-font-bold tw-text-white',
        this.$style.title,
      ];
    },
  },
};
</script>

<style module>
.container {
  background:
    linear-gradient(rgb(41 61 82 / 40%), rgb(41 61 82 / 40%)),
    url('https://blizzard.opensnow.com/images/home-page/home-background-2018.jpg');
  background-position: 50%;
  min-height: 90vh;
}

@media (width >= 768px) {
  .container {
    min-height: 50vh;
  }
}

@media (width >= 992px) {
  .container {
    min-height: 80vh;
  }
}

.title {
  font-size: 2.4rem;
  line-height: 0.9;
}

@media (width >= 768px) {
  .title {
    font-size: 2.8rem;
  }
}

@media (width >= 992px) {
  .title {
    font-size: 3.8rem;
  }
}

.imageColumn {
  height: 25rem;
}

@media (width >= 992px) {
  .imageColumn {
    height: 37.5rem;
  }
}
</style>
